import Stories from 'react-insta-stories';
import type { Story } from 'react-insta-stories/dist/interfaces';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Modal, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

import { TypographyCore } from '~/components/core-components';

import styles from './story-block.module.css';

export const StoryBlock = ({ title, stories }: { title: string; stories: Story[] }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    const handleClick = () => {
        onOpen();
    };
    return (
        <>
            <div className={styles['story-item']} onClick={handleClick}>
                <TypographyCore variant='span' view='bold' size={'l'} color='green-brand' className={styles['story-text']}>
                    {title}
                </TypographyCore>
                <Box className={styles['story-icon']}>
                    <ArrowForwardIcon w='24px' h='24px' color='rgba(79, 130, 100, 1)' transform={'rotate(0.85turn)'} />
                </Box>
            </div>

            {isOpen ? (
                <Modal blockScrollOnMount={true} isOpen={isOpen} isCentered onClose={onClose} autoFocus={false}>
                    <ModalOverlay bg='blackAlpha.700' backdropFilter='auto' backdropInvert='10%' w='100%' h='100%' backdropBlur='5px' />
                    <div className={styles['modal-container']}>
                        <ModalContent borderRadius={'16px'} w='100%'>
                            {isMobile ? <ModalCloseButton zIndex={1000} paddingTop={'30px'} color='rgba(255, 255, 255, 1)' /> : null}

                            <Stories
                                loop={true}
                                stories={stories}
                                defaultInterval={10000}
                                width={isMobile ? '100%' : 449}
                                height={isMobile ? '95vh' : 700}
                                storyContainerStyles={isMobile ? undefined : { borderRadius: '16px' }}
                            />
                        </ModalContent>
                    </div>
                </Modal>
            ) : null}
        </>
    );
};
